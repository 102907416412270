import React from 'react';
import styled from 'styled-components';
import { Section } from '../components/common';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const StyledSection = styled(Section)`
    height: 85vh;
`;

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <StyledSection>
            <h1>404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </StyledSection>
    </Layout>
);

export default NotFoundPage;
